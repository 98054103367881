import React, { ErrorInfo } from 'react'

import { ERROR_BOUNDARY } from '../constants'
import { sendApmError } from '../services/apmService'
import ReloadBanner from './ReloadBanner'

interface ErrorBoundaryProps {
  children: React.ReactElement | React.ReactElement[]
  isMobile: boolean | null
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
}

// there is no hook version of componentDidCatch so we need a class component
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    sendApmError(ERROR_BOUNDARY, `${error}`)
    console.error('Global ERROR caught in ErrorBoundary')
    console.error(error, errorInfo)
  }

  render(): React.ReactElement | React.ReactElement[] {
    if (this.state.hasError) {
      return <ReloadBanner isMobile={this.props.isMobile || false} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
