import { useWeb3React } from '@telekomconsalting/core'
import classNames from 'classnames'
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { refreshTokens } from '../actions/tokenActions'
import { mapToDgWrappedTokenStatic } from '../helpers/wrappedTokenHelpers'
import {
  TokenStaticV2,
  TokenStaticV3,
  TokenV3,
  TokenWithApprovalContext,
  TradeformType,
  TradeType,
} from '../model'
import { State } from '../reducers'
import gtmService from '../services/gtmService'
import { diffsBtwTokenIdLists, getTokenDetails, setQuoteTokenById } from '../services/tokenService'
import {
  getInlineNetworkIconStyle,
  getMarketDisplayName,
  getNetworkConfig,
  isNativeTokenForNetwork,
} from '../utils'
import ButtonToken from './buttons/ButtonToken'
import { FormVariants } from './Form/helpers'
import IconTokenWrapper from './IconTokenWrapper'
import OutsideClicker from './OutsideClicker'
import TokensFormList from './TokensFormList'

interface DropdownFormProps {
  token?: TokenV3
  onChangeToken?: (token?: TokenV3) => void
  tradeType?: TradeType
  onDropDownStateChange?: (value: boolean) => void
  includedInFilter?: boolean
  isShowCurrentToken?: boolean
  network?: string
  isChangeQuoteToken?: boolean
  tradeformType?: TradeformType
}

const DropdownForm: FC<DropdownFormProps> = (props) => {
  const { isMobile } = useSelector((state: State) => state)

  const { currentToken, quoteToken, tokens } = useSelector((state: State) => state.tokens)
  const { account, library } = useWeb3React()
  const [isShowDown, setIsShowDown] = useState(false)
  const [focusInput, setFocusInput] = useState(false)
  const reduxDispatch = useDispatch()

  const { data: networksConfig } = useSelector((state: State) => state.networksConfig)

  const [searchSymbol, setSearchSymbol] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isShowDown) {
      const settingsTokenIds = networksConfig.reduce((acc: string[], curr) => {
        acc = [...acc, ...curr.most_liquid_tokens.map((t) => t.id)]
        return acc
      }, [])
      const tokenIds = tokens.map((token) => token.id)
      const diffsTokenIdList = diffsBtwTokenIdLists(settingsTokenIds, tokenIds)

      if (diffsTokenIdList.length) {
        reduxDispatch(refreshTokens(diffsTokenIdList))
      }
    }

    if (props.onDropDownStateChange) {
      props.onDropDownStateChange(isShowDown)
    }
    inputRef.current?.focus()
    // TODO:
    // https://app.shortcut.com/dexguru/story/21247
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowDown])

  if (!currentToken) {
    return null
  }

  const networkToken = props.token?.network ? props.token.network : currentToken.network
  const networkConfig = getNetworkConfig(networkToken)
  const network = networkConfig.name

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchSymbol(e.target.value.toUpperCase())
  }

  const tokenChoose = async (event: React.MouseEvent): Promise<void> => {
    // if using inside a form led to submit
    event.preventDefault()
    const id = (event.target as HTMLElement)?.closest('button')?.dataset?.id

    if (!id) {
      return
    }

    const quoteToken = await setQuoteTokenById(id, library, account, network)
    if (!quoteToken) {
      return
    }

    if (isMobile && currentToken) {
      // this screen is like ModalVerify on Mobile, need to send Add To Cart action
      gtmService.v3.addToCart(currentToken, quoteToken)
      gtmService.v4.addToCart(currentToken, quoteToken)
    }

    setIsShowDown(false)
    setSearchSymbol('')
  }

  const tokenChooseForFilter = async (event: React.MouseEvent): Promise<void> => {
    // if using inside a form led to submit
    event.preventDefault()
    const id = (event.target as HTMLElement)?.closest('button')?.dataset?.id

    if (!id) {
      return
    }

    const token = await getTokenDetails(id)

    if (props.onChangeToken) {
      props.onChangeToken(token)
    }

    setIsShowDown(false)
    setSearchSymbol('')
  }

  const showDropdown = (event: React.MouseEvent<HTMLButtonElement>): void => {
    // if using inside a form led to submit
    event.preventDefault()
    if (isShowDown) {
      setIsShowDown(false)
      setSearchSymbol('')
    } else {
      setIsShowDown(true)
    }
  }

  const closeDropdown = (): void => {
    setIsShowDown(false)
    setSearchSymbol('')
  }

  const onFocus = (): void => {
    setFocusInput(true)
  }

  const onBlur = (): void => {
    setFocusInput(false)
  }

  let defaultTokens: (TokenStaticV2 | TokenStaticV3)[] = []

  if (currentToken) {
    defaultTokens = (networkConfig.most_liquid_tokens as (TokenStaticV2 | TokenStaticV3)[]).filter(
      (t: TokenStaticV3 | TokenStaticV2) => t.id !== currentToken.id
    )
    // add wrapped token to dropdown in trading form only
    if (
      props.tradeformType === FormVariants.market ||
      (props.tradeformType === FormVariants.limit && !isNativeTokenForNetwork(currentToken))
    ) {
      // add wrapped token
      defaultTokens.push(mapToDgWrappedTokenStatic(networkConfig.native_token))
      if (props.tradeformType === FormVariants.limit) {
        // remove native token because it is restricted for limit orders
        defaultTokens = defaultTokens.filter((x) => x.id !== networkConfig.native_token?.id)
      }
    }
    if (isMobile || props.includedInFilter) {
      defaultTokens = defaultTokens.slice(0, 3)
    }
  }

  const usedToken = props.token || quoteToken

  const styleIcon = getInlineNetworkIconStyle(networkToken, networkConfig)

  return (
    <OutsideClicker clickHide={closeDropdown} className="tradeform-combo__token">
      <React.Fragment>
        <button
          className={classNames('tradeform-token tradeform-combo__toggle', {
            'tradeform-combo__toggle--active': isShowDown,
          })}
          onClick={showDropdown}>
          <div
            className={classNames(
              'tradeform-token__icon',
              'token-ico',
              'token-ico--sm',
              quoteToken?.network ? `token-ico--network-${quoteToken?.network}` : ''
            )}
            style={styleIcon}>
            <IconTokenWrapper
              logoURI={usedToken?.logoURI || ['']}
              symbols={usedToken?.symbols || ['']}
              className="token-ico__image"
            />
          </div>
          <span className="tradeform-token__name" title={getMarketDisplayName(usedToken)}>
            <span className="caption">{getMarketDisplayName(usedToken)}</span>
          </span>
        </button>
        {isShowDown && (
          <div className="tradeform-combo__dropdown">
            <div className="tradeform-tokens">
              <div className="tradeform-tokens__header">
                <strong className="tradeform-tokens__title">Select a token</strong>
              </div>
              <div className="tradeform-tokens__preferred">
                <ul className="row row--gap-sm">
                  {currentToken &&
                    defaultTokens
                      .map((token: { id: string; name: string }) =>
                        tokens.find((t) => t.id === token.id)
                      )
                      .sort((a, b) => (a && b && b.liquidityUSD - a.liquidityUSD) || 0)
                      .map((token: TokenWithApprovalContext | undefined) => (
                        <li className="cell" key={token?.id}>
                          <ButtonToken
                            onClick={props.includedInFilter ? tokenChooseForFilter : tokenChoose}
                            token={token}
                          />
                        </li>
                      ))}
                </ul>
              </div>
              <div
                className={classNames('tradeform-tokens__query', {
                  'tradeform-tokens__query--focus': focusInput,
                })}>
                <input
                  className="tradeform-tokens__input"
                  placeholder="Search token ticker"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={searchSymbol}
                  ref={inputRef}
                />
              </div>
              <TokensFormList
                searchSymbol={searchSymbol}
                setIsShowDown={setIsShowDown}
                isShowDown={isShowDown}
                onChangeToken={props.onChangeToken}
                isShowCurrenntToken={props.isShowCurrentToken}
                network={props.network}
                isChangeQuoteToken={props.isChangeQuoteToken}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    </OutsideClicker>
  )
}

export default DropdownForm
